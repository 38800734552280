import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import classes from './header.module.css';
import React from 'react';

const Header = ({ siteTitle }) => {
  return (
    <header className={classes.header}>
      <Link className={classes.logo} to="/">
        <span
          style={{
            color: '#fff',
            fontWeight: 900,
            letterSpacing: '-0.6px',
            fontSize: '1.2rem',
            textTransform: 'uppercase',
          }}
        >
          Irish Weather API
        </span>
      </Link>

      <nav>
        <ul>
          <li>
            <Link to="/docs" activeClassName={classes.currentPage}>
              Docs
            </Link>
          </li>
          <li>
            <a
              style={{
                border: '0.01rem solid white',
                padding: '0.2rem 0.4rem',
              }}
              href="/weather/api"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              Go to API
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
