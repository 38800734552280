import React from 'react';
import classes from './footer.module.css';
import M from '../images/m.svg';

const Footer = () => {
  return (
    <footer className={classes.footer}>
      <img style={{ maxWidth: '2rem', margin: 0 }} src={M} alt="m logo" />
      <span>
        <span>© {new Date().getFullYear()}</span>
        {` `}
        <span><a href="https://marie.ie">Marie</a></span>
      </span>
    </footer>
  );
};

export default Footer;
